<template>
	
	<el-row type="flex" class="row-bg" justify="center">
		<!-- <el-col :xl="6" :lg="7">
			<h2>欢迎来到旮角教育管理系统</h2>
			<el-image :src="require('@/assets/MarkerHub.jpg')" style="height: 180px; width: 180px;"></el-image>

			<p>xxxx</p>
			<p>xxxxxx</p>

		</el-col>

		<el-col :span="1">
			<el-divider direction="vertical"></el-divider>
		</el-col> -->
		<el-col :xl="6" :lg="7" justify="center" >
			<div class="loginBox">
				<el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="80px" class="input_username">
					<el-form-item label="用户名" prop="username" style="width: 380px; "  >
						<el-input v-model="loginForm.username" ></el-input>
					</el-form-item>
					<el-form-item label="密码" prop="password"  style="width: 380px;">
						<el-input v-model="loginForm.password" type="password"></el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="code"  style="width: 380px;">
						<el-input v-model="loginForm.code"  style="width: 172px; float: left" maxlength="5"></el-input>
						<el-image :src="captchaImg" class="captchaImg" @click="getCaptcha"></el-image>
					</el-form-item>

					<el-form-item style=" text-align: left;">
						<el-button type="primary" @click="submitForm('loginForm')">登录</el-button>
						<!-- <el-button @click="resetForm('loginForm')">重置</el-button> -->
					</el-form-item>
				</el-form>
			</div>

		</el-col>
	</el-row>
	

</template>

<script>

	import qs from 'qs'

	export default {
		name: "Login",
		data() {
			return {
				loginForm: {
					username: '',
					password: '',
					code: '',
					token: ''
				},
				rules: {
					username: [
						{ required: true, message: '请输入用户名', trigger: 'blur' }
					],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' }
					],
					code: [
						{ required: true, message: '请输入验证码', trigger: 'blur' },
						{ min: 5, max: 5, message: '长度为 5 个字符', trigger: 'blur' }
					],
				},
				captchaImg: null,
				requestCount: 0
			};
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post('/login?'+ qs.stringify(this.loginForm)).then(res => {

							const jwt = res.headers['authorization']

							this.$store.commit('SET_TOKEN', jwt)
							this.$router.push("/index")
						}).catch(err => {
							this.getCaptcha()
						})

					} else {
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			getCaptcha() {
				this.$axios.get('/captcha').then(res => {

					this.loginForm.token = res.data.data.token
					this.captchaImg = res.data.data.captchaImg
					this.loginForm.code = ''
				}).catch(res => {
					if(this.requestCount<5)//防止接口异常导致一直调用该请求
						this.getCaptcha();
					this.requestCount++
				})
			}
		},
		created() {
			this.getCaptcha()
		}
	}
</script>

<style scoped>
	
	.el-row {
		background-image: url("https://29343317.s21i.faiusr.com/4/4/ABUIABAEGAAg8cvAowYohsHNVjCAHjiIDg.png");
		background-size: 100% 100%;

		background-color: #fafafa;
		height: 100%;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
	}

	.el-divider {
		height: 200px;
	}

	.captchaImg {
		float: left;
		margin-left: 8px;
		border-radius: 4px;
	}
	.loginBox {
		padding: 53px 20px 20px 20px ;

		background-color: rgba(255, 255, 255, 0.2);
		border-radius: 6px;
		
	}
	
	.input_username /deep/ .el-form-item__label {
		color: white !important;
	}
</style>